import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ta-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccessDeniedComponent {
  @HostBinding('class')
  readonly class = 'access-denied';
}
