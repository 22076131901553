import { ActivatedRouteSnapshot } from '@angular/router';

export const findParam = (route: ActivatedRouteSnapshot, name: string): string | undefined => {
  if (route) {
    const paramKeys = Object.keys(route.params);
    const paramIndex = paramKeys.length > 0 ? paramKeys.findIndex((p) => p === name) : -1;

    return paramIndex < 0 ? (route.firstChild ? findParam(route.firstChild, name) : undefined) : route.params[name];
  }

  return undefined;
};
