<div *ngIf="userInfo">
  <button id="userActionsmenu" mat-button [matMenuTriggerFor]="userActionsmenu">
    <mat-icon>expand_more</mat-icon>
    {{ userInfo.name }}
  </button>
  <mat-menu #userActionsmenu="matMenu">
    <button id="logout" mat-menu-item (click)="onLogoutClick()">
      <mat-icon>logout</mat-icon>
      Logout
    </button>
  </mat-menu>
</div>
