import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export abstract class OnDestroyComponent implements OnDestroy {
  private destroyComponent: Subject<boolean> = new Subject();

  protected get destroy(): Subject<boolean> {
    return this.destroyComponent;
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
