import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RootState } from '@app/store/root.state';
import { AppState } from '@app/store/app.state';

export const selectAppState = createFeatureSelector<RootState, AppState>('app');
export const selectTitle = createSelector(selectAppState, (state) => state.title);
export const selectLanguages = createSelector(selectAppState, (state) => state.languages);
export const selectPageMode = createSelector(selectAppState, (state) => state.pageMode);
export const selectUser = createSelector(selectAppState, (state) => state.user);
export const selectFileFormats = createSelector(selectAppState, (state) => state.fileFormats);
