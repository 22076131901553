import { createAction, props, union } from '@ngrx/store';

import { Language } from '@shared/models';

export enum LanguageActionKey {
  LOAD_LANGUAGES_SUCCESS = '[GLOBAL/Language] Load Languages Success'
}

export const loadLanguagesSuccess = createAction(LanguageActionKey.LOAD_LANGUAGES_SUCCESS, props<{ languages: Language[] }>());

const actions = union({
  loadLanguagesSuccess
});

export type Actions = typeof actions;
