import { Injectable } from '@angular/core';
import { AuthConfig } from '@shared/models/auth-config';

import { apiEndpoint } from '@shared/api-endpoint';
import { environment } from 'src/environments/environment';

@Injectable()
export class ConfigurationService {
  get apiUrl(): string {
    return environment.apiUrl;
  }

  get authConfig(): AuthConfig {
    return environment.auth as AuthConfig;
  }

  get apiEndpoint(): typeof apiEndpoint {
    return apiEndpoint;
  }
}
