export {};

declare global {
  interface Array<T> {
    lastOrDefault(): T | undefined;
    firstOrDefault(): T | undefined;
    groupBy(key: string): { [keyValue: string]: T[] };
  }
}

Array.prototype.lastOrDefault = function <T>(): T | undefined {
  return this.length > 0 ? this[this.length - 1] : undefined;
};

Array.prototype.firstOrDefault = function <T>(): T | undefined {
  return this.length > 0 ? this[0] : undefined;
};

Array.prototype.groupBy = function <T>(key: string): { [keyValue: string]: T[] } {
  return this.reduce((reduceValue: { [keyValue: string]: T[] }, item: T) => {
    const keyValue = item[key].toString();
    (reduceValue[keyValue] = reduceValue[keyValue] || []).push(item);

    return reduceValue;
  }, {});
};
