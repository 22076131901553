import { createAction, props, union } from '@ngrx/store';

import { TranslationFileFormat } from '@shared/models';

export enum TranslationFileFormatActionKey {
  LOAD_TRANSLATION_FILE_FORMATS_SUCCESS = '[GLOBAL/TranslationFileFormat] Load TranslationFileFormats Success'
}

export const loadTranslationFileFormatsSuccess = createAction(TranslationFileFormatActionKey.LOAD_TRANSLATION_FILE_FORMATS_SUCCESS, props<{ fileFormats: TranslationFileFormat[] }>());

const actions = union({
  loadTranslationFileFormatsSuccess
});

export type Actions = typeof actions;
