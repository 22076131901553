import { readonly } from '@core/decorators/readonly';
import { UserRoleInfo } from '@shared/models/user/user-role';
import { UserRole } from '@shared/enums/user-role';

export interface UserInfo {
  email: string;
  name: string;
}

export class User implements UserInfo {
  email: string;
  name: string;
  roles: UserRoleInfo[];

  constructor(user: Partial<User>) {
    Object.assign(this, user);
  }

  @readonly
  hasRole(role: UserRole): boolean {
    return this.roles?.findIndex((r) => r.displayName.toLocaleLowerCase().replace(/\s/g, '') === role) >= 0 ?? false;
  }
}
