import { createReducer, on } from '@ngrx/store';

import { AppState } from '@app/store/app.state';
import * as SpinnerActions from '@app/store/actions/spinner.actions';
import * as ErrorActions from '@app/store/actions/error.actions';
import * as LanguagesActions from '@app/store/actions/language.actions';
import * as AppActions from '@app/store/actions/app.actions';
import * as TranslationFileFormatActions from '@app/store/actions/translation-file-format.actions';

export const initialState: AppState = {
  user: undefined,
  errors: [],
  spinner: [],
  title: 'Translation Portal',
  languages: [],
  pageMode: undefined,
  fileFormats: []
};

export const appReducer = createReducer(
  initialState,

  //#region Spinner Actions
  on(SpinnerActions.showSpinner, (state, { key }) => {
    if (state.spinner.indexOf(key) >= 0) {
      throw Error('"key" value must be unique');
    }

    return {
      ...state,
      spinner: [...state.spinner, key]
    };
  }),
  on(SpinnerActions.hideSpinner, (state, { key }) => ({
    ...state,
    spinner: state.spinner.filter((k) => k !== key)
  })),
  //#endregion

  //#region Error Actions
  on(ErrorActions.addError, (state, { error }) => ({
    ...state,
    errors: [...state.errors, error]
  })),
  //#endregion

  //#region Language Actions
  on(LanguagesActions.loadLanguagesSuccess, (state, { languages }) => ({
    ...state,
    languages
  })),
  //#endregion

  //#region App Actions
  on(AppActions.setPageMode, (state, { pageMode }) => ({
    ...state,
    pageMode
  })),
  on(AppActions.setUser, (state, { user }) => ({
    ...state,
    user
  })),
  //#endregion

  //#region FileFormats Actions
  on(TranslationFileFormatActions.loadTranslationFileFormatsSuccess, (state, { fileFormats }) => ({
    ...state,
    fileFormats
  }))
  //#endregion
);
