export const apiEndpoint = {
  product: {
    getAll: () => '/products',
    get: (productId: number) => `${apiEndpoint.product.getAll()}/${productId}`,
    add: () => apiEndpoint.product.getAll(),
    update: (productId: number) => `${apiEndpoint.product.getAll()}/${productId}`
  },
  request: {
    getAll: () => '/requests',
    getUploadsignedUrl: (productId: number) => `/signedurl/upload/${productId}`,
    getLocalAll: () => '../../assets/requests.json',
    getSignedUrlForExport: (requestId: number) => `${apiEndpoint.request.getAll()}/${requestId}/download`
  },
  language: {
    getAll: () => '/languages'
  },
  version: {
    getAll: () => '/versions',
    add: () => apiEndpoint.version.getAll(),
    update: (versionId: number) => `${apiEndpoint.version.getAll()}/${versionId}`,
    get: (versionId: number) => `${apiEndpoint.version.getAll()}/${versionId}`,
    language: {
      getAll: (versionId: number) => `${apiEndpoint.version.getAll()}/${versionId}/languages`,
      add: (versionId: number) => apiEndpoint.version.language.getAll(versionId),
      update: (languageId: number, versionId: number) => `${apiEndpoint.version.language.getAll(versionId)}/${languageId}`,
      delete: (languageId: number, versionId: number) => apiEndpoint.version.language.update(languageId, versionId)
    },
    export: (versionId: number) => `${apiEndpoint.version.getAll()}/${versionId}/export`,
    getSignedUrl: () => `${apiEndpoint.version.getAll()}/importrequest`
  },
  component: {
    getAll: () => '/components',
    get: (componentId: number) => `${apiEndpoint.component.getAll()}/${componentId}`,
    add: () => apiEndpoint.component.getAll(),
    update: (componentId: number) => `${apiEndpoint.component.getAll()}/${componentId}`,
    delete: (componentId: number) => `${apiEndpoint.component.getAll()}/${componentId}`,
    translations: {
      import: () => '/importTranslations',
      export: () => '/exportTranslations'
    },
    elements: {
      create: () => '/importElements'
    },
    fileFormats: {
      getTranslationFileFormats: () => '/translationFileFormats'
    }
  },
  release: {
    getAll: () => '/releases',
    create: () => apiEndpoint.release.getAll()
  }
};
