import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '@shared/services/auth.service';
import { NavigationService } from '@shared/services/navigation.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private navigationService: NavigationService) {}

  canActivate(): Observable<boolean> {
    return this.authService.isAuthenticated$().pipe(
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          this.navigationService.redirectToGssoCallback();
        }

        return isAuthenticated;
      })
    );
  }
}
