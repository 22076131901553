import { createAction, props, union } from '@ngrx/store';

export enum SpinnerActionKey {
  SHOW_SPINNER = '[GLOBAL/spinner] Show Spinner',
  HIDE_SPINNER = '[GLOBAL/spinner] Hide Spinner'
}

export const showSpinner = createAction(SpinnerActionKey.SHOW_SPINNER, props<{ key: string }>());
export const hideSpinner = createAction(SpinnerActionKey.HIDE_SPINNER, props<{ key: string }>());

const actions = union({
  showSpinner,
  hideSpinner
});

export type Actions = typeof actions;
