import { createAction, props, union } from '@ngrx/store';

import { PageMode } from '@shared/index';
import { User } from '@shared/models/user';

export enum AppActionKey {
  SET_PAGE_MODE = '[GLOBAL/App] Set Page Mode',
  SET_USER = '[GLOBAL/App] Set User',
  APP_LOGOUT = '[GLOBAL/App] Logout'
}

export const setPageMode = createAction(AppActionKey.SET_PAGE_MODE, props<{ pageMode: PageMode }>());
export const setUser = createAction(AppActionKey.SET_USER, props<{ user: User }>());
export const logout = createAction(AppActionKey.APP_LOGOUT);

const actions = union({
  setPageMode,
  setUser,
  logout
});

export type Actions = typeof actions;
