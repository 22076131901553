import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth.service';
import { NavigationService } from '@shared/services/navigation.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private navigationService: NavigationService) {}
  readonly filterUrls = ['amazonaws'];

  private isUrlNotContianFilterUrls = (url: string): boolean => {
    return this.filterUrls.some((str) => !url.includes(str));
  };

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.token;

    if (this.isUrlNotContianFilterUrls(req.url)) {
      if (token) {
        req = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`)
        });
      }
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 401:
            this.navigationService.redirectToGssoCallback();
            break;
          case 403:
            this.navigationService.redirectToAccessDenied();
            break;
        }

        return throwError(error);
      })
    );
  }
}
