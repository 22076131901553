import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class IconService {
  constructor(private iconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  registerCustomIcons(): void {
    this.addSvgIcon('microsoft_logo', 'assets/icons/svg/microsoft.svg');
    this.addSvgIcon('ert_logo', 'assets/icons/svg/logo.svg');
    this.addSvgIcon('download', 'assets/icons/svg/download.svg');
  }

  private addSvgIcon(name: string, path: string): void {
    this.iconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
  }
}
