import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { iif, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { LanguageService } from '@shared/services';
import { AppSelectors, LanguageActions } from '@app/store';

@Injectable()
export class PreloadLanguagesGuard implements CanActivate {
  constructor(private store: Store, private languageService: LanguageService) {}

  canActivate(): Observable<boolean> {
    const selectLanguages = this.store.pipe(select(AppSelectors.selectLanguages));

    return selectLanguages.pipe(
      switchMap((storedLanguages) =>
        iif(() => !!storedLanguages?.length, of(storedLanguages), this.languageService.getAll$().pipe(tap((languages) => this.store.dispatch(LanguageActions.loadLanguagesSuccess({ languages })))))
      ),
      map(() => true)
    );
  }
}
