import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, Store, StoreModule } from '@ngrx/store';
import { NavigationActionTiming, routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import '@core/extensions/array';

import { LayoutModule } from '@app/layout/layout.module';
import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { AuthGuard, AuthService, ConfigurationService, NavigationService, SpinnerModule } from '@shared/index';
import { appReducer } from '@app/store/reducers/app.reducer';
import { RootState } from '@app/store/root.state';
import { CoreModule } from '@core/core.module';
import { AppEffects } from '@app/store/effects/app.effects';
import { AppActions } from '@app/store';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MultiselectComponent } from './shared/components/multiselect/multiselect.component';

/**
 * @ignore
 */
const appReducers: ActionReducerMap<RootState> = {
  router: routerReducer,
  app: appReducer
};

/**
 * @ignore
 */
export function authenticationFactory(authService: AuthService, store: Store): () => Promise<void> {
  return (): Promise<any> =>
    authService
      .authenticate$()
      .toPromise()
      .then((user) => store.dispatch(AppActions.setUser({ user })));
}

@NgModule({
  declarations: [AppComponent, MultiselectComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    SpinnerModule,
    LayoutModule,
    CoreModule,
    NgxWebstorageModule.forRoot(),
    StoreModule.forRoot(appReducers, {
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true
      }
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25
    }),
    EffectsModule.forRoot([AppEffects]),
    StoreRouterConnectingModule.forRoot({
      navigationActionTiming: NavigationActionTiming.PostActivation
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: authenticationFactory,
      deps: [AuthService, Store],
      multi: true
    },
    ConfigurationService,
    NavigationService,
    AuthService,
    AuthGuard
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
