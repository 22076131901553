import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { iif, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { TranslationFileFormatService } from '@shared/services';
import { AppSelectors, TranslationFileFormatActions } from '@app/store';

@Injectable()
export class PreloadTranslationFileFormatsGuard implements CanActivate {
  constructor(private store: Store, private translationFileFormatService: TranslationFileFormatService) {}

  canActivate(): Observable<boolean> {
    const selectFileFormats = this.store.pipe(select(AppSelectors.selectFileFormats));

    return selectFileFormats.pipe(
      switchMap((storedFileFormats) =>
        iif(
          () => !!storedFileFormats?.length,
          of(storedFileFormats),
          this.translationFileFormatService.getAll$().pipe(tap((fileFormats) => this.store.dispatch(TranslationFileFormatActions.loadTranslationFileFormatsSuccess({ fileFormats }))))
        )
      ),
      map(() => true)
    );
  }
}
