import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { MenuItem } from '@shared/index';
import { SpinnerActions, SpinnerSelectors } from '@app/store';
import { AuthService } from '@shared/services/auth.service';
import { NavigationService } from '@shared/services/navigation.service';

/**
 * Root component
 */
@Component({
  selector: 'ta-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  /**
   * A key to be used for identifying route navigation events when spinner is ON
   */
  readonly routeSpinnerKey: string = 'routing';
  /**
   * Spinner's status
   */
  isLoading: Observable<boolean>;
  /**
   * App's menu list
   */
  menu: MenuItem[];

  constructor(private router: Router, private store: Store, public authService: AuthService, private navigationService: NavigationService) {}

  /**
   * Lifecycle hook
   */
  ngOnInit(): void {
    this.initMenu();
    this.isLoading = this.store.pipe(select(SpinnerSelectors.selectLoadingState));
    this.registerRouterEvents();

    window.addEventListener('storage', (event) => {
      if (event.storageArea === localStorage) {
        this.authService.isAuthenticated$().subscribe((isAuthenticated) => {
          if (!isAuthenticated) {
            this.navigationService.redirectToGssoCallback();
          }
        });
      }
    });
  }

  /**
   * Register global router event
   */
  registerRouterEvents(): void {
    this.addSpinnerOnRoutes();
  }

  /**
   * Initialize app's menu
   */
  private initMenu(): void {
    this.menu = [
      { text: 'Products', link: 'products', icon: 'dashboard' },
      { text: 'Requests', link: 'requests', icon: 'link' }
    ];
  }

  /**
   * Enables spinner in case of having Resolvers or Guards when global routing occures
   */
  private addSpinnerOnRoutes(): void {
    this.router.events.subscribe((e: RouterEvent) => {
      switch (true) {
        case e instanceof NavigationStart: {
          this.store.dispatch(SpinnerActions.showSpinner({ key: this.routeSpinnerKey }));
          break;
        }

        case e instanceof NavigationError:
        case e instanceof NavigationCancel:
        case e instanceof NavigationEnd: {
          this.store.dispatch(SpinnerActions.hideSpinner({ key: this.routeSpinnerKey }));
          break;
        }
      }
    });
  }
}
