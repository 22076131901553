import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigurationService } from '@shared/services/configuration.service';

@Injectable()
export class BaseApiService {
  constructor(protected httpClient: HttpClient, protected configurationService: ConfigurationService) {}

  buildUrl(endpoint: string): string {
    return this.sanitize(`${this.configurationService.apiUrl}/${endpoint}`);
  }

  protected sanitize(url: string): string {
    return url.replace(/([^:]\/)\/+/g, '$1');
  }
}
