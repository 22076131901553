import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Action, Store } from '@ngrx/store';

import { PageMode } from '@shared/enums';
import { AppActions } from '@app/store';

interface Config {
  value: PageMode;
  actions?: Action[];
}

export const CONFIG_KEY = 'pageMode';
export const getConfig = (pageMode: PageMode, actions?: Action[]): Config => ({
  value: pageMode,
  actions
});

@Injectable()
export class SetPageModeGuard implements CanActivate {
  constructor(protected store: Store) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const config = this.getConfig(route);

    if (!!config) {
      this.store.dispatch(AppActions.setPageMode({ pageMode: config.value }));
      config.actions?.forEach((a) => this.store.dispatch(a));
    }

    return true;
  }

  protected getConfig(route: ActivatedRouteSnapshot): Config {
    return !!route && !!route.data && route.data[CONFIG_KEY];
  }
}
