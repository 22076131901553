import { createAction, props, union } from '@ngrx/store';

export enum ErrorActionKey {
  ADD_ERROR = '[GLOBAL/Error] Add Error'
}

export const addError = createAction(ErrorActionKey.ADD_ERROR, props<{ error: Error }>());

const actions = union({
  addError
});

export type Actions = typeof actions;
