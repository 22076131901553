import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseApiService } from '@shared/services/base-api.service';
import { Language } from '@shared/models';

@Injectable()
export class LanguageService extends BaseApiService {
  getAll$(): Observable<Language[]> {
    const url = this.buildUrl(this.configurationService.apiEndpoint.language.getAll());

    return this.httpClient.get<Language[]>(url);
  }
}
