export const environment = {
  production: true,
  apiUrl: 'https://management.translation.int2.clario.com',
  auth: {
    redirectUri: window.location.origin,
    loginUrl: 'https://gsso-02.int.ert.com/service/api/v1/oauth2/authorize',
    clientId: 'translationservice',
    logoutUrl: 'https://gsso-02.int.ert.com/gsso/services/api/v1/sessions/logout',
    checkSessionUrl: 'https://gsso-02.int.ert.com/gsso/services/api/v1/sessions/check',
    rolesUrl: 'https://gsso-02.int.ert.com/gsso/services/api/v2/users/roles'
  }
};
