import { Directive, Input, Optional, TemplateRef } from '@angular/core';

@Directive({
  selector: '[taTemplate]'
})
export class TemplateDirective {
  @Input('taTemplate') name: string;

  constructor(@Optional() public templateRef: TemplateRef<any>) {}
}
