import { Component, ContentChild, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { MenuItem, TemplateDirective } from '@shared/index';
import { UserInfo } from '@app/shared/models/user';
import { AppActions, AppSelectors } from '@app/store';

/**
 * @ignore
 */
@Component({
  selector: 'ta-layout, div[ta-layout]',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit {
  @HostBinding('class')
  class = 'layout-container';

  @Input()
  menu: MenuItem[];

  user$: Observable<UserInfo>;

  @ContentChild(TemplateDirective, { static: false })
  content: TemplateDirective;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.user$ = this.store.pipe(select(AppSelectors.selectUser));
  }

  onLogout(): void {
    this.store.dispatch(AppActions.logout());
  }
}
