<mat-sidenav-container class="nav-container" [hasBackdrop]="true">
  <!-- Menu -->
  <mat-sidenav #nav mode="over" position="end">
    <mat-nav-list>
      <a mat-list-item *ngFor="let menuItem of menu" [routerLink]="menuItem.link" (click)="nav.close()">
        <mat-icon>{{ menuItem.icon }}</mat-icon>
        <span class="nav-item-label">{{ menuItem.text }}</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <!-- Content -->
  <mat-sidenav-content>
    <!-- Header -->
    <mat-toolbar class="header-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <a [routerLink]="['/']" fxLayoutAlign="center center" class="logo">
          <mat-icon svgIcon="ert_logo"></mat-icon>
        </a>
      </div>

      <ta-user-info class="user-info" [userInfo]="user$ | async" (logout)="onLogout()"></ta-user-info>

      <button mat-icon-button (click)="nav.toggle()" role="menu">
        <mat-icon aria-hidden="true" role="presentation">menu</mat-icon>
      </button>
    </mat-toolbar>

    <div class="app-content">
      <ng-container *ngTemplateOutlet="content?.templateRef"></ng-container>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
