import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
import jwt_decode from 'jwt-decode';

import { SessionCode } from '@shared/enums/session-code.enum';
import { CheckSessionResponse } from '@shared/models/check-session.response';
import { ConfigurationService } from '@shared/services/configuration.service';
import { User, UserInfo, UserRoleInfo } from '@shared/models/user';
import { LogoutStatusResponse } from '@shared/models/logout-status';
import { LogoutStatus } from '@shared/enums/logout-status.enum';

@Injectable()
export class AuthService {
  private readonly TOKEN_URL_PARAMETER = 'id_token';
  private readonly EXPIRES_IN_URL_PARAMETER = 'expires_in';
  private readonly TOKEN_STORAGE_KEY = 'ta_token';
  private readonly TOKEN_EXPIRES_STORAGE_IN_KEY = 'ta_token_expires_in';

  constructor(private router: Router, private location: Location, private storageService: LocalStorageService, private configurationService: ConfigurationService, private httpClient: HttpClient) {}

  get token(): string {
    const tokenData = this.storageService.retrieve(this.TOKEN_STORAGE_KEY);
    return tokenData ? tokenData : null;
  }

  isAuthenticated$(): Observable<boolean> {
    if (this.token) {
      return this.httpClient.get(this.configurationService.authConfig.checkSessionUrl).pipe(
        map((response: CheckSessionResponse) => response.code === SessionCode.Success),
        catchError(() => of(false))
      );
    }

    return of(false);
  }

  logout$(): Observable<LogoutStatusResponse> {
    return this.httpClient.get<LogoutStatusResponse>(this.configurationService.authConfig.logoutUrl).pipe(
      map(() => this.handleSuccessLogout()),
      catchError((e: HttpErrorResponse) => of(this.handleErrorLogout(e)))
    );
  }

  getUserRoles$(): Observable<UserRoleInfo[]> {
    return this.httpClient.get<any>(this.configurationService.authConfig.rolesUrl).pipe(map((response) => response?.roles));
  }

  getUserFromToken$(token: string): Observable<User> {
    const userInfo = this.tryDecodeToken(token);
    const mapToUser = (roles: UserRoleInfo[]) => {
      const { name, email } = userInfo;
      return new User({ name, email, roles });
    };

    return !!userInfo ? this.getUserRoles$().pipe(map(mapToUser)) : of(undefined);
  }

  /**
   * Authenticate a user from the url when we get redirected from GSSO.
   * @returns A decoded user token
   */
  authenticate$(): Observable<User> {
    const url = window.location.href;

    // If we got redirected from GSSO, process parameters
    if (this.isGssoUrl(url)) {
      const { token, expiresIn } = this.parseGssoParameters(window.location.href);
      this.storageService.store(this.TOKEN_STORAGE_KEY, token);
      this.storageService.store(this.TOKEN_EXPIRES_STORAGE_IN_KEY, expiresIn);

      // Replaces current url string
      const urlTree = this.router.createUrlTree(['/']);
      this.location.replaceState(urlTree.toString());

      return this.getUserFromToken$(token);
    }

    return this.getUserFromToken$(this.token);
  }

  private tryDecodeToken(token: string): UserInfo {
    try {
      return jwt_decode<UserInfo>(token);
    } catch (e) {
      console.error(e);
    }
  }

  private isGssoUrl(url: string): boolean {
    return url.includes(this.TOKEN_URL_PARAMETER) && url.includes(this.EXPIRES_IN_URL_PARAMETER);
  }

  private parseGssoParameters(url: string): { token: string; expiresIn: string } {
    const httpParams = new HttpParams({ fromString: url.split('#').lastOrDefault() });
    const token = httpParams.get(this.TOKEN_URL_PARAMETER);
    const expiresIn = httpParams.get(this.EXPIRES_IN_URL_PARAMETER);

    return { token, expiresIn };
  }

  private handleSuccessLogout(): LogoutStatusResponse {
    const response: LogoutStatusResponse = {
      status: LogoutStatus.Success
    };
    this.storageService.clear(this.TOKEN_STORAGE_KEY);
    this.storageService.clear(this.TOKEN_EXPIRES_STORAGE_IN_KEY);

    return response;
  }

  private handleErrorLogout(err: HttpErrorResponse): LogoutStatusResponse {
    const response: LogoutStatusResponse = {
      status: err.status === LogoutStatus.Unauthorized ? LogoutStatus.Unauthorized : LogoutStatus.ServerError
    };

    return response;
  }
}
