import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TemplateDirective } from '@shared/directives/template/template.directive';

@NgModule({
  declarations: [TemplateDirective],
  imports: [CommonModule],
  exports: [TemplateDirective],
  providers: []
})
export class TemplateModule {}
