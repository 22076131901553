import { Component, Input, Output } from '@angular/core';
import { UserInfo } from '@shared/models/user';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'ta-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent {
  @Input()
  userInfo: UserInfo;

  @Output()
  logout: EventEmitter<void> = new EventEmitter();

  onLogoutClick(): void {
    this.logout.emit();
  }
}
