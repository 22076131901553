import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseApiService } from '@shared/services/base-api.service';
import { TranslationFileFormat } from '@shared/models';

@Injectable()
export class TranslationFileFormatService extends BaseApiService {
  getAll$(): Observable<TranslationFileFormat[]> {
    const url = this.buildUrl(this.configurationService.apiEndpoint.component.fileFormats.getTranslationFileFormats());

    return this.httpClient.get<TranslationFileFormat[]>(url);
  }
}
