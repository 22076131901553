import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ConfigurationService } from '@shared/services/configuration.service';

/**
 * @ignore
 */
@Injectable()
export class NavigationService {
  constructor(private configurationService: ConfigurationService, private router: Router) {}

  redirectToGssoCallback(): void {
    window.location.href = `${this.configurationService.authConfig.loginUrl}?${this.buildGssoQueryParams().toString()}`;
  }

  redirectToAccessDenied(): void {
    this.router.navigate(['access-denied'], { skipLocationChange: true });
  }

  private buildGssoQueryParams(): HttpParams {
    return new HttpParams()
      .append('response_type', 'token id_token')
      .append('scope', 'openid profile')
      .append('nonce', '0') // TODO: should be random GUID
      .append('client_id', this.configurationService.authConfig.clientId)
      .append('redirect_uri', `${this.configurationService.authConfig.redirectUri}/`);
  }
}
