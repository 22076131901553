import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AppActions } from '@app/store';
import { AuthService } from '@shared/services/auth.service';
import { NavigationService } from '@shared/services/navigation.service';
import { LogoutStatusResponse } from '@shared/models/logout-status';
import { LogoutStatus } from '@shared/enums/logout-status.enum';

@Injectable()
export class AppEffects {
  constructor(private actions$: Actions, private authService: AuthService, private navigationService: NavigationService) {}

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.logout),
        switchMap(() => this.handleLogout$())
      ),
    { dispatch: false }
  );

  private handleLogout$ = (): Observable<void> =>
    this.authService.logout$().pipe(
      map((status: LogoutStatusResponse) => {
        if (status.status === LogoutStatus.Success) {
          this.navigationService.redirectToGssoCallback();
        }
      })
    );
}
