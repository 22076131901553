import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { MenuListItemComponent } from '@shared/components/list-item/menu-list-item.component';
import { NavListItemComponent } from '@shared/components/list-item/nav-list-item.component';

@NgModule({
  declarations: [MenuListItemComponent, NavListItemComponent],
  imports: [CommonModule, MatMenuModule, FlexLayoutModule, MatIconModule],
  exports: [MenuListItemComponent, NavListItemComponent],
  providers: []
})
export class ListItemModule {}
